import fyp_app from  "../assets/images/projects/fyp-app.png";
import fyp_mic from  "../assets/images/projects/fyp-mic.png";
import photo_society from  "../assets/images/projects/photo-society.png";
import Skills from "./Skills";

function Projects() {
    const handleClickPhoto = () => {
        window.open("https://limerickps.atspace.eu/", "_blank");
    };

    const handleClickFyp = () => {
        window.open("https://github.com/kaflitun/flourish", "_blank");
    };

    return (
        <div className="container section text-center" id="projects">

            <div className="row justify-content-center section-heading-container mb-4">
                <div className="col-md-8">
                    <h1 className="section-heading">Projects</h1>
                    <p>Information about some of my projects</p>
                </div>
            </div>


            <div className="row justify-content-center align-items-center prj-container">
                <div className="col-md-6 text-md-right prj-img-container">
                    <img src={fyp_app} alt="Flourish mobile app" className="img-fluid project-img" />
                </div>
                <div className="col-md-5 text-md-left project-desc">
                    <h2>Flourish</h2>
                    <p>
                        Mobile application that uses sensors and AI to improve a plant care routine by identifying
                        plant diseases and monitoring plant conditions. This solution aims to provide plant
                        owners with care recommendations based on gathered data about plant conditions to improve
                        plant care management and, as a result, plant health and growth.
                    </p>
                    <Skills items={["Kotlin", "C++", "MongoDB", "ESP32","NPK sensor",
                        "Google API","GPT API","Git","Figma","Firebase"]} />
                    <button onClick={handleClickFyp} className="btn btn-primary">View Project</button>
                </div>
            </div>
            <div className="row justify-content-center align-items-center prj-container">
                <div className="col-md-6 text-md-right prj-img-container">
                    <img src={photo_society} alt="Photo Society website" className="img-fluid project-img" />
                </div>

                <div className="col-md-5 text-md-left project-desc">
                    <h2>Limerick photography society </h2>
                    <p>
                        Limerick photography society website. The website is designed to showcase the work of the society members,
                        provide information about the society, and allow new members to join the society.
                    </p>
                    <Skills items={["HTML", "CSS", "JavaScript"]} />
                    <button onClick={handleClickPhoto} className="btn btn-primary">View Project</button>
                </div>
            </div>
        </div>
    );
}

export default Projects;