import React from 'react';

function Skills(props) {
    const skills = props.items;

    const rows = [];
    for (let i = 0; i < skills.length; i += 4) {
        rows.push(skills.slice(i, i + 4));
    }

    return (
            <div className="skills-grid">
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} className="row mb-3">
                        {row.map((skill, skillIndex) => (
                            <div key={skillIndex} className="col-6 col-md-3 skill-box">
                                <div className="p-2 border rounded text-center">
                                    {skill}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
    );
}

export default Skills;