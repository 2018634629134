import Skills from "./Skills";

function About(){
    const languages = [
        "Java (Spring)",
        "C# (.NET)",
        "C++",
        "Python",
        "JS (React)",
        "Kotlin",
        "PHP",
        "SQL",
        "CSS",
        "HTML"
    ];
    const tools = [
        "Docker",
        "Git",
        "GitLab",
        "AWS",
        "WSL-Ubuntu",
        "Bash",
        "Postman",
        "RapidMiner"
    ];

    const databases = [
        "MySQL",
        "MongoDB"
    ];

    return(
        <div className="row justify-content-center align-items-center text-center section" id="about">
            <div className="row section-heading-container">
                <h1 className="section-heading">About Me</h1>
                <p>Information about my skills and background</p>
            </div>
            <div className="row" id="about-me-row">
                <div className="col section-col-l" id="about-me-section">
                    <h2>My background</h2>
                    <p className="even-text">I am a recent graduate with First Class Honours
                        in Software Development from the Technological University of the Shannon, Limerick.
                        My education, combined with hands-on experience from internships and personal projects,
                        has equipped me with a strong foundation in both frontend and backend development.</p>
                    <p className="even-text">
                        I completed an internship at Jaguar Land Rover, where I worked on the project related to vehicle safety.
                        This experience strengthened my skills in software testing, debugging, and development,
                        and enhanced my problem-solving abilities in practical applications.
                    </p>
                    <p className="even-text">
                        I have also been recognised with several academic awards, highlighting my commitment to excellence
                        and passion for software development.
                        These achievements inspire me to keep learning and to contribute to impactful projects in the tech industry.
                    </p>
                </div>
                <div className="col section-col-r" id="skills-section">
                    <h2>Skills</h2>
                    <div className="row">
                        <h3>Languages</h3>
                        <Skills items={languages}/>
                    </div>
                    <div className="row">
                        <h3>Databases</h3>
                        <Skills items={databases}/>
                    </div>
                    <div className="row">
                        <h3>Tools</h3>
                        <Skills items={tools}/>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default About